import React from "react";

const Home: React.FC = () => {
  return (
    <div className="flex justify-center flex-col items-center h-full text-white text-3xl">
      <div>
        <p>
          martin
        </p>
      </div>
    </div>
  )
}

export default Home;

import React from 'react';

const PageNotFound: React.FC = () => (
  <div className='flex flex-col justify-center text-center text-white h-full'>
    <h1>Page Not Found</h1>
    <p>Are you sure this is the page you were looking for?</p>
  </div>
);

export default PageNotFound;
